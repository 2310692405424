import * as $GoodsAPI from "@/api/goods";
import { exportSheet } from "@/pages/goods/services/utils";
import store from "@/store";

export interface ListGoodsApiParams {
  page_no?: number;
  page_size?: number;
  shopType?: number;
  category_path?: string; // 分类路径
  is_auth?: number; // 0 待审核, 1 审核通过, 2 审核拒绝
  supplier_enable?: number; // 1 上架, 0 下架
  keyword?: string; // 关键词
  goods_ptype?: number; // 1 实物商品 2 虚拟商品
  low_price?: number; // 最低供货价
  high_price?: number; // 最高供货价
  goods_name?: string;
  goods_sn?: number;
  sort?: string;
  key_word?: string;
}

export interface ExportGoodsApiParams {
  excludeGoodsId?: number[];
  checkedGoodsId?: number[];
}

export class GoodsItemProxy {
  isJDSupplier: true; // 是否京东供应商
  cover: string; // 封面
  id: string;
  sn: string; // 商品编码
  name: string; // 商品名称
  JDSn: string; // 京东编码
  auth_message: string; // 京东编码
  categoryName: string; // 分类名称
  ext_brand_name: string;// 商品品牌
  specsName: string; // 规格名称
  profitRate: number; // 利润率
  marketPrice: number; // 市场价
  supplyPrice: number; // 供货价
  centralizedPurchasePrice: number; // 集采价

  from: number; // 源自：0普通商品，1京东商品，2苏宁商品
  isFromNormalGoods: boolean;
  isFromJDGoods: boolean;
  isFromSNlGoods: boolean;
  isFromUncleCake: boolean;
  quantity: number; // 库存
  totalSales: number; // 总销量
  createTime: number; // 创建时间

  saleStatus: number; // 在售状态
  saleStatusText: string; // 在售状态文本
  isOnSale: boolean;

  authStatus: number; // 审核状态
  authStatusText: string; // 审核状态文本

  constructor(public raw: any) {
    this.isJDSupplier = raw.isJDSupplier;

    this.cover = raw.thumbnail;
    this.id = raw.goods_id;
    this.sn = raw.sn;
    this.name = raw.goods_name.replaceAll("amp;", "").replaceAll(" ", "&nbsp;");
    this.JDSn = raw.ext_goods_id;
    this.auth_message = raw.auth_message;
    this.categoryName = raw.cat_name;
    this.ext_brand_name = raw.ext_brand_name;
    this.specsName = raw.specs;
    this.profitRate = raw.platform_profit;
    this.marketPrice = raw.mktprice;
    this.supplyPrice = raw.cost;
    this.centralizedPurchasePrice = raw.original_price;

    this.from = raw.goods_source;
    this.isFromNormalGoods = this.from === 0;
    this.isFromJDGoods = this.from === 1;
    this.isFromSNlGoods = this.from === 2;
    this.isFromUncleCake = this.from === 3;
    this.quantity = raw.quantity;
    this.totalSales = raw.buy_count;
    this.createTime = raw.create_time;

    this.saleStatus = raw.supplier_enable;
    this.isOnSale = raw.supplier_enable === 1;
    this.saleStatusText = this.isOnSale ? "已上架" : "已下架";

    this.authStatus = raw.is_auth;
    this.authStatusText = (() => {
      if (this.saleStatusText === "已下架" && this.authStatus !== 2) {
        return "待提交";
      }
      switch (this.authStatus) {
        case 0:
          return "审核中";
        case 1:
          return "审核通过";
        case 2:
          return "审核拒绝";
        default:
          return "";
      }
    })();
  }

  edit() {
    const { $router } = window["app"];
    console.log(this);

    return new Promise((resolve) => {
      if (this.isFromJDGoods) {
        $router.push({
          name: "goodEditJD",
          params: {
            goodsid: this.id,
            isdraft: 1,
            callback: resolve,
          } as any,
        });
      } else if (this.isFromUncleCake) {
        $router.push({
          name: "goodEditCake",
          params: {
            goodsid: this.id,
            isdraft: 1,
            callback: resolve,
          } as any,
        });
      } else {
        $router.push({
          name: "goodEdit",
          params: {
            goodsid: this.id,
            isdraft: 1,
            callback: resolve,
          } as any,
        });
      }
    });
  }

  remove() {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve) => {
      $confirm("确认删除此商品？", "提示", {
        type: "warning",
      }).then(() => {
        const _ids = [this.id].toString();
        $GoodsAPI.deleteGoods(_ids, {}).then(() => {
          resolve(true);
          $message.success("商品删除成功！");
        });
      });
    });
  }

  putAway() {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve) => {
      $confirm("确认上架此商品？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI.shelves(this.id, {}).then(() => {
          $message.success("上架成功");
          resolve(true);
        });
      });
    });
  }

  offShelf() {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve) => {
      $confirm("确认下架此商品？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI["underGoods"](this.id, {}).then(() => {
          $message.success("下架成功");
          resolve(true);
        });
      });
    });
  }
}

class GoodsListService {
  remove(idList, params) {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve) => {
      $confirm("确认删除此商品？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI.deleteGoods(idList, params).then(() => {
          $message.success("商品删除成功！");
          resolve(true);
        });
      });
    });
  }

  offShelf(idList, params, isJDSupplier = true) {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve) => {
      $confirm("确认下架商品？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI[isJDSupplier ? "underGoods" : "batchTakedown"](
          idList,
          params
        ).then(() => {
          $message.success("操作成功");
          resolve(true);
        });
      });
    });
  }

  upperGoods(idList, params, isJDSupplier = true) {
    const { $confirm, $message } = window["app"];
    return new Promise<any>((resolve, reject) => {
      $confirm("确认上架商品？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI[isJDSupplier ? "upperGoods" : "batchPutaway"](
          idList,
          params
        ).then((res) => {
          if (res.code === -1) return $message.error(res.message);
          if (isJDSupplier) {
            const { totalCount, successCount, failureCount, failureList } = res;
            $message.success("上架成功");
            const msg = `
            批量上架成功。<br/>
            本次共操作${totalCount}条数据，其中成功${successCount}条，失败${failureCount}条。<br/>
            <span style='font-size:12px;color:red;'>(京东已删除的商品，会从当前系统中删除)</span>`;
            $confirm(msg, "提示", {
              type: "warning",
              showCancelButton: false,
              showConfirmButton: failureList.length > 0,
              confirmButtonText: "下载失败数据",
              dangerouslyUseHTMLString: true,
            }).then(() => {
              this.exportTemplate(failureList, `失败数据-${Date.now()}`, [
                {
                  name: "商品编号",
                  key: "goods_sn",
                },
                {
                  name: "京东编号",
                  key: "ext_goods_id",
                },
                {
                  name: "商品名称",
                  key: "goods_name",
                },
                {
                  name: "失败原因",
                  key: "error_info",
                },
              ]);
            }, reject);
            resolve(true);
          } else {
            $message.success("上架成功");
            resolve(true);
          }
        }, reject);
      }, reject);
    });
  }

  exportTemplate(
    data: any[] = [],
    filename = "商品上架失败模板",
    extraFields: any[] = []
  ) {
    console.log("上架失败数据");
    if (data.length === 0) data.push({});

    data = data.map((d) => {
      return extraFields.reduce((obj, field) => {
        obj[field.name] = d[field.key];
        return obj;
      }, {} as any);
    });

    exportSheet({ values: data }, filename);
  }

  list(params: ListGoodsApiParams) {
    return $GoodsAPI.getGoodsList(params).then((resp) => {
      console.log(resp.data);

      resp.data = resp.data.map((d) => new GoodsItemProxy(d));
      return resp;
    });
  }

  exportData(params: ExportGoodsApiParams) {
    return $GoodsAPI.exportList(params);
  }
}

/**
 * Goods List Template
 */
interface TemplateField {
  name: string;
  key: string;
  required?: boolean;
  type?: "string" | "number";
}

class GoodsListTemplate {
  fields: TemplateField[] = [
    {
      name: "商品编号",
      key: "sn",
    },
    {
      name: "商品名称",
      key: "goods_name",
      required: true,
    },
    {
      name: "商品一级分类",
      key: "first_name",
    },
    {
      name: "商品二级分类",
      key: "second_name",
    },
    {
      name: "商品三级分类",
      key: "three_name",
    },
    {
      name: "供货价",
      key: "cost",
    },
    {
      name: "市场价",
      key: "mktprice",
      type: "number",
    },
    {
      name: "集采价",
      key: "original_price",
      type: "number",
    },
    {
      name: "总库存(件)",
      key: "quantity",
      required: true,
      type: "number",
    },
  ];

  exportTemplate(
    data: any[] = [],
    filename = "商品导入模板",
    options: { extraFields?: TemplateField[]; fields?: TemplateField[] } = {}
  ) {
    let fields = options.fields || this.fields;

    if (options.extraFields) {
      fields = [...fields, ...options.extraFields];
    }

    if (data.length === 0) data.push({});

    data = data.map((d) => {
      return fields.reduce((obj, field) => {
        obj[field.name] = d[field.key];
        return obj;
      }, {} as any);
    });

    exportSheet({ values: data }, filename);
  }

  private detectTemplateData(data: any[], files: TemplateField[]) {
    const { $message } = window["app"];
    const results: any[] = [];

    if (data.length === 0) {
      $message.error("模板中未检测到数据，请填入至少一条数据后重新导入！");
      return null;
    }

    for (const goods of data) {
      // 检测每一个字段值是否正确
      for (let i = 0; i < files.length; i++) {
        const field = files[i];
        let value = goods[field.name];
        const isNull = value === undefined || value === null || value === "";
        field.type = field.type || "string";

        if (field.required && isNull) {
          $message.error(`【${field.name}】是必填项，请检查数据后重新导入！`);
          return null;
        }

        if (!isNull) {
          switch (field.type) {
            case "number":
              value = parseFloat(value);
              if (isNaN(value)) {
                $message.error(
                  `【${field.name}】必须为数字，请检查数据后重新导入！`
                );
                return null;
              }
              break;
            case "string":
              if (typeof value !== "string") value = JSON.stringify(value);
              break;
          }
        }

        // 将字段从中文转换为英文
        goods[field.key] = value;
        delete goods[field.name];
      }
      results.push(goods);
    }

    return results;
  }

  importTemplate(data: any[], files: TemplateField[] = this.fields) {
    return new Promise<any>((resolve) => {
      const { $message, $confirm } = window["app"];

      const results = this.detectTemplateData(data, files);

      if (!results) return;

      $GoodsAPI.importNewList(results).then((resp) => {
        const { sum, ok, no, errorExport } = resp;

        if (no === 0) {
          $message.success("数据已成功导入！");
          resolve(true);
        } else {
          const msg = `本次共导入${sum}条数据，其中成功导入${ok}条，失败${no}条。是否下载导入失败的数据表？`;
          $confirm(msg, "提示", { type: "warning" }).then(() => {
            this.exportTemplate(errorExport, `导入失败的数据-${Date.now()}`, {
              extraFields: [
                {
                  name: "失败原因",
                  key: "remark",
                },
              ],
            });
          });
        }
      });
    });
  }
}

export const $goodsListTemplate = new GoodsListTemplate();
export const $goodsList = new GoodsListService();
